import React, { useEffect } from 'react'
import StudentForm from './StudentForm'
import Adminroute from './admin/Adminroute'
import Home from './admin/Home'
import { BrowserRouter as Router ,Route, Routes, BrowserRouter } from 'react-router-dom';
import StudentRoute from './StudentRoute';
import { useAuth } from './AuthContext';
const App = () => {
const {loadEmployee}=useAuth();
useEffect(()=>{

  loadEmployee();

},[])
  return (
   <>

   <Adminroute/>
 
    </>

  )
}

export default App