import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Spinner,
  Center,
  useToast,
  Select,
  Text,
  Badge,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  HStack,
} from '@chakra-ui/react';
import Home from './Home';
import axios from 'axios';
const backend_url=process.env.REACT_APP_BACKEND_URL

const StudentsList = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [school, setSchool] = useState([]);
  const [schoolValue, setSchoolValue] = useState();
  const [refresh, setRefresh] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterByClass, setFilterByClass] = useState();
  const toast = useToast();
  useEffect(() => {
    const fetchSchool = async () => {
      try {
        const { data } = await axios.get('/api/v1/schoollist');
        setSchool(data.data);
      } catch (error) {
        toast({
          title: "Error fetching school data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchSchool();
  }, [toast]);




  useEffect(() => {
    if (!schoolValue) return setLoading(false);
  
    setLoading(true);

//if()

    const url = filterByClass 
      ? `${backend_url}/api/v1/studentlist/${schoolValue}/${filterByClass}` 
      : `${backend_url}/api/v1/studentlist/${schoolValue}`;
  
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setStudents(data.data);
        } else {
          toast({
            title: "Error fetching data.",
            description: "There was an error fetching the student data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching students:', error);
        toast({
          title: "Error fetching data.",
          description: "There was an error fetching the student data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      });
  }, [toast, schoolValue, filterByClass]);
  

  const deleteStudent = async (id) => {
    try {
      await axios.delete('/api/v1/delete/student', { data: { id } });
      toast({
        title: "Student deleted successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setRefresh((refresh) => refresh + 1);
    } catch (error) {
      console.error(error);
      toast({
        title: "Please try again.",
        description: error.response?.data?.message || "An unexpected error occurred.",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const handleEditClick = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedStudent((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
  };

  const handleUpdateStudent = async () => {
    try {
      await axios.put('/api/v1/update/student', selectedStudent);
      toast({
        title: "Student updated successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setRefresh((refresh) => refresh + 1);
      handleModalClose();
    } catch (error) {
      console.error(error);
      toast({
        title: "Please try again.",
        description: error.response?.data?.message || "An unexpected error occurred.",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }



  const downloadExcelFile = async () => {
    try {
      if (!schoolValue ) {
        return alert('Please Select School');
      }
  let baseUrl=filterByClass?`${backend_url}/api/v1/excelfile/create/${schoolValue}/${filterByClass}`:`${backend_url}/api/v1/excelfile/create/${schoolValue}`

      // Make the request to get the Excel file
      const response = await axios.get(baseUrl, {
        responseType: 'blob', // Important to handle the file response
      });
  
      // Create a URL for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'output.zip'); // or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
  
      toast({
        title: "Downloading ...",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Please try again.",
        description: error.response?.data?.message || "An unexpected error occurred.",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  };
  




  return (
    <>
      <Home />
      <Box  mx="auto" >
        <HStack justifyContent={'space-around'} p={'5'} position={'sticky'} w={'100%'} top={'0'} bg={'green.100'} zIndex={'1'}>
        <Box >
          
          <Select placeholder='--Select School--' onChange={(e) => setSchoolValue(e.target.value)} size={'sm'} w={'250px'}>
            {school && school.map((item, i) => (
              <option key={i} value={item.schoolName}>{item.schoolName}</option>
            ))}
          </Select>
        </Box>
        <Box>
        

        <Select placeholder='--Select Class--' size={'sm'} onChange={(e)=>setFilterByClass(e.target.value)} value={filterByClass}>

<option value='L.K.G.' >L.K.G.</option>
<option value='U.K.G.'>U.K.G.</option>
<option value='01'>01</option>
<option value='02'>02</option>
<option value='03'>03</option>
<option value='04'>04</option>
<option value='05'>05</option>
<option value='06'>06</option>
<option value='07'>07</option>
<option value='08'>08</option>
<option value='09'>09</option>
<option value='10'>10</option>
<option value='11'>11</option>
<option value='12'>12</option>
</Select>



        </Box>

<Box>
  
  <Button  size={'sm'}  colorScheme='green' borderRadius={'2'}
  
  onClick={()=>downloadExcelFile()}
  
  shadow={'md'}
  variant={'outline'}
  >Download</Button>



</Box>
        </HStack>

<Box p={'5'}>

        {students && students.length < 1 ? (
          <Box><Badge color="red">Student Not Found in Specified School!</Badge></Box>
        ) : (
          <Table  colorScheme='teal' size='xs'>
            <Thead>
              <Tr>
                <Th>S.No.</Th>
                <Th>Photo</Th>
                <Th>Name</Th>
                <Th>Father's Name</Th>
                <Th>Class</Th>
                <Th>Address</Th>
                <Th>Roll No</Th>
                <Th>School Name</Th>
                <Th>Mobile No</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {students.map((student,index) => (
                <Tr key={student._id}>
                    <Td>{index+1}</Td>
                  <Td>
                    <Image
                      src={`${backend_url}/${student.photoUrl}`}
                      alt={student.name}
                      boxSize="50px"
                      objectFit="cover"
                      borderRadius="full"
                    />
                  </Td>
                  <Td>{student.name}</Td>
                  <Td>{student.fatherName}</Td>
                  <Td>{student.className}</Td>
                  <Td>{student.address}</Td>
                  <Td>{student.rollNumber}</Td>
                  <Td>{student.schoolName}</Td>
                  <Td>{student.mobileNumber}</Td>
                  <Td>
                    <Button size={'sm'} borderRadius={'2'} colorScheme={'blue'} variant='outline' onClick={() => handleEditClick(student)}>Edit</Button>

                    <Button size={'sm'} borderRadius={'2'}  colorScheme={'red'} variant='outline' ml={2} onClick={() => deleteStudent(student._id)}>Delete</Button>

                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        </Box>
      </Box>

      {selectedStudent && (
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Student</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={4}>
                <FormLabel>Name</FormLabel>
                <Input name="name" value={selectedStudent.name} onChange={handleInputChange} />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Father's Name</FormLabel>
                <Input name="fatherName" value={selectedStudent.fatherName} onChange={handleInputChange} />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Class</FormLabel>
                {/*<Input name="className" value={selectedStudent.className} onChange={handleInputChange} />*/}
           

                <Select onChange={handleInputChange} value={selectedStudent.className} name="className">

<option value='L.K.G.' >L.K.G.</option>
<option value='U.K.G.'>U.K.G.</option>
<option value='01'>01</option>
<option value='02'>02</option>
<option value='03'>03</option>
<option value='04'>04</option>
<option value='05'>05</option>
<option value='06'>06</option>
<option value='07'>07</option>
<option value='08'>08</option>
<option value='09'>09</option>
<option value='10'>10</option>
<option value='11'>11</option>
<option value='12'>12</option>
</Select>


      
              </FormControl>



              <FormControl mb={4}>
                <FormLabel>Address</FormLabel>
                <Textarea name="address" value={selectedStudent.address} onChange={handleInputChange} />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Roll No</FormLabel>
                <Input name="rollNumber" value={selectedStudent.rollNumber} onChange={handleInputChange} />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>School Name</FormLabel>
                <Input name="schoolName" value={selectedStudent.schoolName} onChange={handleInputChange} />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>Mobile Number</FormLabel>
                <Input
                  name="mobileNumber"
                  value={selectedStudent.mobileNumber}
                  onChange={handleInputChange}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleUpdateStudent}>
                Save
              </Button>
              <Button variant="ghost" onClick={handleModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default StudentsList;
