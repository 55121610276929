import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Home from './Home'; // Adjust the path as needed
//import Admin from './Admin'; // Adjust the path as needed
import StudentsList from './StudentsList';
import GenerateLink from './GenerateLink';
import Login from './Login';
import { AuthProvider } from '../AuthContext';
import ProtectedRoute from './ProtectedRoute';
import StudentForm from '../StudentForm';
import { useAuth } from '../AuthContext';
import Dashboard from './Dashboard';
const Adminroute = () => {
  const { user } = useAuth();
//let role=user.role
  return (
    <>  
  
        <Routes>
         
           <Route path="/" element={user && user.role=='admin'?<Home/>:<Login/>}/>
           <Route path="/admin/generatelink" element={user && user.role=='admin'?<GenerateLink/>:<Login/>}/>
           <Route path="/student/list" element={user && user.role=='admin'?<StudentsList/>:<Login/>}/>
           
           <Route path="/dashboard" element={user && user.role=='admin'?<Dashboard/>:<Login/>}/>
           
          
          
          {/*<Route path="/admin/generatelink" element={<GenerateLink />} />*/}
            {/*<Route path="/student/list" element={<StudentsList />} />*/}
          <Route path='/student/:school' element={<StudentForm/>}/>
          {/*<Route path="/login" element={<Login/>} />*/}
        </Routes>
        </>
    
  );
};

export default Adminroute;
