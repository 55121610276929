import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  useToast,
  Text,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const StudentForm = () => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: '',
    fatherName: '',
    className: '',
    address: '',
    rollNumber: '',
    schoolName: '',
    mobileNumber: '',
    photo: null,
  });
  const { school } = useParams();


const [schoollist ,setSchoollist]=useState([]);

  useEffect(() => {
    const fetchSchool = async () => {
      try {
        const { data } = await axios.get('/api/v1/schoollist');
        setSchoollist(data.data);
      } catch (error) {
        toast({
          title: "Error fetching school data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchSchool();
  }, [toast]);




let newSchoollist =schoollist.map((item)=>{return(item.schoolName)})

//console.log(newSchoollist)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, photo: e.target.files[0] });
  };

  useEffect(() => {
    if (school) {
      setFormData((prevData) => ({ ...prevData, schoolName: school }));
    }
  }, [school]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      await axios.post('/api/v1/', formDataToSend);
      toast({
        title: 'Form Submitted.',
        description: 'Student details have been recorded.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      const errorMessage = error.response?.data?.message || "An error occurred. Please try again.";
      toast({
        //title: 'Already eist',
        description: errorMessage,
        status: 'warning',
        duration: 5000,
        position:'top',
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="md" mx="auto" mt={5} p={5} borderWidth="1px" borderRadius="lg" boxShadow="lg">
     
     
      
     {newSchoollist && newSchoollist.includes(school) ?(
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <FormControl isRequired>
            <Text fontSize="20" fontWeight="800" color="green.400">{school}</Text>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Student Photo</FormLabel>
            <Input type="file" accept="image/*" onChange={handleFileChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Student Name</FormLabel>
            <Input type="text" name="name" placeholder="Enter student name" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Father's Name</FormLabel>
            <Input type="text" name="fatherName" placeholder="Enter father's name" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Class</FormLabel>
            {/*<Input type="text" name="className" placeholder="Enter class" onChange={handleChange} />*/}

            <Select onChange={handleChange} name="className">

<option value='L.K.G.' >L.K.G.</option>
<option value='U.K.G.'>U.K.G.</option>
<option value='01'>01</option>
<option value='02'>02</option>
<option value='03'>03</option>
<option value='04'>04</option>
<option value='05'>05</option>
<option value='06'>06</option>
<option value='07'>07</option>
<option value='08'>08</option>
<option value='09'>09</option>
<option value='10'>10</option>
<option value='11'>11</option>
<option value='12'>12</option>
</Select>



          </FormControl>
          <FormControl isRequired>
            <FormLabel>Address</FormLabel>
            <Textarea name="address" placeholder="Enter address" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Roll Number</FormLabel>
            <Input type="text" name="rollNumber" placeholder="Enter roll number" onChange={handleChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Mobile Number</FormLabel>
            <Input
              type="tel"
              name="mobileNumber"
              placeholder="Enter 10-digit mobile number"
              maxLength="10"
              pattern="[0-9]{10}"
              onChange={handleChange}
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" width="full">
            Submit
          </Button>
        </Stack>
      </form>):(<Text>Expired Url or School Name </Text>)
      
     }

    </Box>
  );
};

export default StudentForm;
