// AuthContext.js
import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Replace with actual user fetching logic

  const login = (userData) => {
    console.log('useData',userData);
    setUser(userData); // Replace with actual login logic
  };

  const loadEmployee=async()=>{

try {
  const {data} = await axios.get('/api/v1/load/employee')
  setUser(data.employee);
  
} catch (error) {
  console.log(error)
}

  }

  const logout = async() => {

await axios.post('/api/v1/logout/employee');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout,loadEmployee }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
