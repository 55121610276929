// admin/GenerateLink.js
import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Spinner, useToast } from '@chakra-ui/react';
import axios from 'axios';
import Home from './Home';

const GenerateLink = () => {
  const [schoolName, setSchoolName] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleGenerateLink = async () =>{
    try {
      setIsLoading(true)
      const response = await axios.post('/api/v1/generatelink', { schoolName });

console.log(response)
        
      setLink(response.data.link);

      toast({
        title: "Link Generated.",
        description: `The link is: ${response.data.link}`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false)
    } catch (error) {
      toast({
        title: "Error",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false)
  };

  return (
    <>
    <Home/>
    <Box maxW="md" mx="auto" mt={5} p={5} borderWidth="1px" borderRadius="lg" boxShadow="lg">
      <FormControl isRequired>
        <FormLabel>School Name</FormLabel>
        <Input
          type="text"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
          placeholder="Enter school name"
        />
      </FormControl>
      <Button mt={4} colorScheme="blue" onClick={handleGenerateLink}>
        Generate Link
      </Button>
      {isLoading?<Spinner size={'md'} color='red'/>:<>
      {link && (
        <Box mt={4} p={2} bg="gray.100" borderRadius="md">
          <strong>Generated Link:</strong> <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
        </Box>
      )}
      </>}
    </Box>
    </>
  );
};

export default GenerateLink;
