import React, { useState, useEffect } from 'react';
import { Box, Flex, HStack, Stack, Text, useToast, VStack, Table, Td, Tr, Tbody, Button, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure ,Badge} from "@chakra-ui/react";
import { SiVlcmediaplayer } from "react-icons/si";
import Home from './Home';
import axios from 'axios';

const Dashboard = () => {
  const [schools, setSchools] = useState([]);
  const [students, setStudents] = useState([]);
  const [editableIndex, setEditableIndex] = useState(null);
  const [editedSchoolName, setEditedSchoolName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();


  const [studentlist,setStudentlist]=useState()
  const toast = useToast();

  const fetchSchools = async () => {
    try {
      const { data } = await axios.get('/api/v1/schoollist');
      setSchools(data.data);
    } catch (error) {
      toast({
        title: "Error fetching school data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const deleteSchool = async (id) => {
    try {
      await axios.delete('/api/v1/delete/school', { data: { id } });
      toast({
        title: "School deleted successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      fetchSchools();
    } catch (error) {
      toast({
        title: "Please try again.",
        description: error.response?.data?.message || "An unexpected error occurred.",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (index, schoolName) => {
    setEditableIndex(index);
    setEditedSchoolName(schoolName);
  };

  const handleSave = async (id) => {
    try {
      await axios.put('/api/v1/update/school', { id, schoolName: editedSchoolName });
      toast({
        title: "School name updated successfully.",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
      setEditableIndex(null);
      fetchSchools();
    } catch (error) {
      console.log(error, id, editedSchoolName)
      toast({
        title: "Error updating school name.",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const { data } = await axios.get('/api/v1/students/list');
        setStudents(data.data);
      } catch (error) {
        toast({
          title: "Error fetching student data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchStudents();
  }, []);




const FindStudent=async(school)=>{
try {
  const {data}=await axios.get(`/api/v1/studentlist/${school}`)
  console.log(data.students)
  setStudentlist(data.data)
  onOpen()
} catch (error) {
  
}

}








  return (
    <>
      <Home />

      <Flex flexWrap="wrap" gap={4} justifyContent="center" p={4}>
        <Box w={{ base: "100%", sm: "45%", md: "30%" }} h={{ base: "100px", sm: "150px", md: "100px" }} bg="#e5e4e2" color="navy" borderRadius="md" textAlign="center" p={4} boxShadow="md" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Stack>
            <HStack justify="center">
              <Box bg="orange" p={4} borderRadius="50%" boxShadow="md">
                <SiVlcmediaplayer size={24} />
              </Box>
              <Text fontSize={{ base: "sm", sm: "md", md: "lg" }} fontWeight="bold">Total School</Text>
            </HStack>
            <Text fontSize={{ base: "sm", sm: "md", md: "lg" }} fontWeight="bold">{schools.length}</Text>
          </Stack>
        </Box>

        <Box w={{ base: "100%", sm: "45%", md: "30%" }} h={{ base: "100px", sm: "150px", md: "100px" }} bg="#e5e4e2" color="navy" borderRadius="md" textAlign="center" p={4} boxShadow="md" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Stack>
            <HStack justify="center">
              <Box bg="orange" p={4} borderRadius="50%" boxShadow="md">
                <SiVlcmediaplayer size={24} />
              </Box>
              <Text fontSize={{ base: "sm", sm: "md", md: "lg" }} fontWeight="bold">Total Students</Text>
            </HStack>
            <Text fontSize={{ base: "sm", sm: "md", md: "lg" }} fontWeight="bold">{students.length}</Text>
          </Stack>
        </Box>
      </Flex>

      <Box>
        <VStack>
          <Table size={'sm'} w={'60%'}>
            <Tbody>
              {schools.map((item, index) => (
                <Tr key={item._id}>
                  <Td>
                    <Input
                      type='text'
                      minWidth='200px'
                      value={editableIndex === index ? editedSchoolName : item.schoolName}
                      readOnly={editableIndex !== index}
                      onChange={(e) => setEditedSchoolName(e.target.value)}
                    />
                  </Td>
                  <Td>
                    {editableIndex === index ? (
                      <Button size={'sm'} colorScheme={'green'} onClick={() => handleSave(item._id)}>Save</Button>
                    ) : (
                      <Button size={'sm'} colorScheme={'blue'} onClick={() => handleEdit(index, item.schoolName)}>Edit</Button>
                    )}
                  </Td>

                  <Td><Button size={'sm'} colorScheme={'red'} onClick={() => deleteSchool(item._id)}>Delete</Button></Td>
                  <Td>{item.link}</Td>
                  <Td><Button size={'sm'} colorScheme={'green'} onClick={
                    //onOpen,FindStudent()
                    ()=>
                    FindStudent(item.schoolName)

                       
                    
                    
                    }>View</Button></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </VStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          {/*<HStack w={'500'}>*/}

          <ModalHeader>Total Count :{"  "
}

       {studentlist && studentlist.length>0? studentlist.length:'Not available '}

          </ModalHeader>


          {/*</HStack>*/}
          <ModalCloseButton />
          <ModalBody>
            <Table size={'sm'} w={'100%'}>
              <Tbody>
                {studentlist  && studentlist.length>0 && studentlist.map((school) => (
                  <Tr key={school._id}>
                    <Td>{school.name}</Td>
                    <Td>{school.address}</Td>
                    {/* You can add more fields here */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Dashboard;
