import React from 'react';
import { 
  Box, 
  Flex, 
  VStack, 
  HStack, 
  IconButton, 
  useDisclosure, 
  Stack, 
 
  Button,Text
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import StudentsList from './StudentsList';
import { Link } from 'react-router-dom';
import StudentRoute from '../StudentRoute';
import { useAuth } from '../AuthContext';
//const NavLink = ({ children }) => (
//  <Link
//    px={2}
//    py={1}
//    rounded={'md'}
//    _hover={{
//      textDecoration: 'none',
//      bg: 'gray.200',
//    }}
//    href={'#'}>
//    {children}
//  </Link>
//);

const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
const {logout}=useAuth()
  return (
    <Box>
      <Flex 
        as="nav" 
        bg="blue.500" 
        color="white" 
        minH="60px" 
        py={{ base: 2 }} 
        px={{ base: 4 }} 
        borderBottom={1} 
        borderStyle="solid" 
        borderColor="gray.200" 
        align="center"
      >
        <Flex 
          flex={{ base: 1, md: 'auto' }} 
          ml={{ base: -2 }} 
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={isOpen ? onClose : onOpen}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        <HStack spacing={8} alignItems="center">
          <HStack
            as="nav"
            spacing={4}
            display={{ base: 'none', md: 'flex' }}
          >
            {/*<NavLink >Dashboard</NavLink>
            <NavLink>Students List</NavLink>
            <NavLink>Generate Link</NavLink>
            <NavLink>Logout</NavLink>*/}
            <Link to={'/dashboard'}>
            <Box as='button' bgSize={'xl'}>Dashboard</Box>
            
            </Link>
            <Link to={'/student/list'}>
            <Box as='button'>Students List</Box>
            
            </Link>

            <Link to={`/admin/generatelink`}>
            <Box as='button'>Generate Link</Box>
            
            </Link>
            <Box as='button'  onClick={()=>logout()}>Logout</Box>



          </HStack>
        </HStack>
        <Flex
          flex={{ base: 1, md: 0 }}
          justify="flex-end"
          display={{ base: 'none', md: 'flex' }}
        >
          {/*<Button
            as="a"
            fontSize="sm"
            fontWeight={400}
            variant="link"
            href="#"
          >
            Sign In
          </Button>*/}
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            {/*<NavLink>Home</NavLink>
            <NavLink>About</NavLink>
            <NavLink>Students</NavLink>
            <NavLink>Contact</NavLink>*/}
          </Stack>
        </Box>
      ) : null}

      
    </Box>
  );
};

export default Home;
